<template>
  <div></div>
</template>

<script>
import _ from "lodash";

export default {
  name: "BaseListPage",
  data() {
    return {
      //Current item to work with
      selectedItem: null,

      //Status flag whether dialog is visible
      editDialog: false,
      filterDialog: false,

      //Status flag whether loader should be visible
      dataLoading: false,
      pageReady: false,

      //List of translated attribute label
      attributeLabels: {},

      //List of attributes allowed for ordering
      orderAttributes: [],

      defaultFilters: {},
      multipleFilters: {},
      currentRouteName: this.$route.name,
    };
  },
  computed: {
    //Get order number of last showed row
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
    //Get formatted attribute labels
    attributes: function () {
      let items = [];
      let attributeLabels = this.attributeLabels;

      this.orderAttributes.forEach(function (attribute) {
        items.push({
          attribute,
          label: _.get(attributeLabels, attribute, ""),
        });
      });
      return items;
    },
    formattedFilters: function () {
      return {};
    },
    emptyFilters: function () {
      return {};
    },
  },
  watch: {
    //Reload items after ordering changed
    sort: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    //Reload items after new page selected
    //Set new url with selected page and filters
    pagination: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        if (this.pagination.page > 1) {
          query.page = this.pagination.page;
        } else {
          delete query.page;
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query,
          })
          .catch(() => {});
        this.loadItems();
      },
      deep: true,
    },
    //Reload items after new filters selected
    //Set new url with selected filters, reset selected page
    filters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        for (let filter in this.filters) {
          delete query[filter];
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query: _.merge(query, this.formattedFilters),
          })
          .catch(() => {});
        this.pagination.page = 1;
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    applyFilters: function (data) {
      return data;
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    applyRouteParams: function () {
      let status = false;
      let component = this;
      if (!_.isEmpty(component.$route.query)) {
        let filters = {};
        _.forIn(component.$route.query, function (value, key) {
          if (_.has(component.emptyFilters, key)) {
            if (_.indexOf(component.multipleFilters, key) > -1) {
              let values = [];
              if (!(value instanceof Array)) {
                value = [value];
              }
              value.forEach(function (valueItem) {
                values.push(isNaN(valueItem) ? valueItem : parseInt(valueItem));
              });
              filters[key] = values;
            } else {
              filters[key] = isNaN(value) ? value : parseInt(value);
            }
          }
        });
        const data = _.merge({}, component.emptyFilters, filters);
        component.applyFilters({ data });
        if (component.$route.query.page) {
          component.pagination.page = parseInt(component.$route.query.page);
        }
        status = true;
      }
      this.pageReady = true;
      return status;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.applyFilters({
        data: _.merge({}, vm.defaultFilters),
      });
      vm.pagination.page = 1;
    });
  },
  mounted() {
    this.applyRouteParams();
  },
};
</script>
