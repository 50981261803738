<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pr-2 pl-4">
      <v-row>
        <v-col sm="12" md="5" lg="3">
          <b>{{ settings.title }}</b>
          <div v-if="hasFormattedSegment">
            <div v-for="(segment, label) in formattedSegment" :key="label">
              <span class="grey--text body-2 mb-0">
                {{ attributeLabels[label] }}:
              </span>
              {{ prepareAttributeValue(label, segment) }}
            </div>
          </div>
          <div v-else>{{ $t("recommender.allAccounts") }}</div>
        </v-col>
        <v-col sm="12" md="7" lg="4">
          <v-chip small class="mr-2 mb-2"
            >{{ $t("recommender.friends.matching.geo") }}:
            {{ settings.matching.geo }}</v-chip
          >
          <v-chip small class="mr-2 mb-2"
            >{{ $t("recommender.friends.matching.interest") }}:
            {{ settings.matching.interest }}</v-chip
          >
          <v-chip small class="mr-2 mb-2"
            >{{ $t("recommender.friends.matching.friend") }}:
            {{ settings.matching.friend }}</v-chip
          >
          <v-chip
            v-for="(tag, index) in settings.matching.fuzzyTags"
            :key="index"
            small
            class="mr-2 mb-2"
            >{{ $t("recommender.friends.matching.tag") }} {{ tag.tagSlag }}:
            {{ tag.value }}</v-chip
          >
        </v-col>
        <v-col cols="2" sm="12" md="5" lg="2">
          <v-chip
            small
            outlined
            :color="getColorByStatus({ status: settings.status })"
            class="px-2"
          >
            {{ $t("recommender.status")[settings.status] }}
          </v-chip>
        </v-col>
        <v-col cols="2" sm="9" md="5" lg="2">
          {{ settings.updatedAt }}
        </v-col>
        <v-col cols="2" sm="3" md="2" lg="1">
          <v-btn
            x-small
            icon
            color="primary"
            class="mr-2"
            @click="$emit('editInfo')"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="!settings.base"
            x-small
            icon
            color="primary"
            @click="$emit('deleteItem')"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "FriendsRecommenderItem",
  props: {
    settings: Object,
  },
  data: function () {
    return {
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        tagId: this.$t("account.attribute.tags"),
        stage: this.$t("account.attribute.stage"),
      },
      //List of selected related items
      selectedTags: [],
    };
  },
  computed: {
    ...mapGetters("friends", [
      "availableScopes",
      "availableActions",
      "availableSegments",
      "getColorByStatus",
    ]),
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
      "getAbrByLanguage",
    ]),
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.settings.segment, function (value, key) {
        if (value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
  },
  methods: {
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      const component = this;
      if (_.isArray(value)) {
        return value
          .map(function (itemValue) {
            return component.prepareAttributeValue(attribute, itemValue);
          })
          .join(", ");
      }
      switch (attribute) {
        case "language": {
          let language = _.find(this.availableLanguageLabels, {
            key: value,
          });
          if (language) {
            result = language.label;
          }
          break;
        }
        case "gender": {
          let gender = _.find(this.availableGenders, {
            gender: value,
          });
          if (gender) {
            result = gender.key;
          }
          break;
        }
        case "tagId": {
          let tag = _.find(this.settings.tags, { id: value });
          result = tag ? tag.title : "";
          break;
        }
      }
      return result;
    },
  },
};
</script>
