<template>
  <v-col sm="6" md="4" lg="3">
    <v-autocomplete
      :items="items"
      item-text="label"
      item-value="key"
      :label="label"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      deletable-chips
      multiple
      small-chips
      hide-no-data
      hide-selected
      name="ageGroup"
      @input="handleInput"
    >
      <v-icon
        v-if="removeble"
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AgeGroupSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    removeble: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  computed: {
    ...mapGetters("account/donor", ["availableAges"]),
    items: function () {
      let items = [];
      this.availableAges.forEach(function (item) {
        items.push({
          key: item,
          label: item,
        });
      });
      if (this.allowEmpty) {
        items.unshift({
          key: "notSet",
          label: this.$t("notSet"),
        });
      }

      return items;
    },
  },
};
</script>
