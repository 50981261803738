<template>
  <v-col sm="6" md="4" lg="2">
    <v-autocomplete
      :items="typeItems"
      item-text="title"
      item-value="id"
      :loading="isTypeLoading"
      :search-input.sync="typeSearch"
      menu-props="closeOnContentClick"
      @change="typeSearch = ''"
      deletable-chips
      multiple
      hide-no-data
      hide-selected
      small-chips
      name="type"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        v-if="removeble"
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "TypeSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    removeble: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isTypeLoading: false,
      //Filter values
      types: [],
      //Filter entered value
      typeSearch: "",
    };
  },
  watch: {
    typeSearch: function () {},
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  computed: {
    typeItems: function () {
      return this.types;
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    fetchAccountTypes: function () {
      this.isTypeLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
          search: {
            selectedIds: this.itemValue,
          },
        })
        .then(
          (payload) => {
            this.isTypeLoading = false;
            this.types = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchAccountTypes();
  },
};
</script>
